import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6ac58f48 = () => interopDefault(import('../pages/applicant-profile/index.vue' /* webpackChunkName: "pages/applicant-profile/index" */))
const _6c1e1473 = () => interopDefault(import('../pages/browse.vue' /* webpackChunkName: "pages/browse" */))
const _44cf9fd4 = () => interopDefault(import('../pages/browser-outdated.vue' /* webpackChunkName: "pages/browser-outdated" */))
const _539e158a = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _5037fb36 = () => interopDefault(import('../pages/job-offer/index.vue' /* webpackChunkName: "pages/job-offer/index" */))
const _156a50ee = () => interopDefault(import('../pages/message.vue' /* webpackChunkName: "pages/message" */))
const _1245ecf1 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _0cf10a50 = () => interopDefault(import('../pages/message/accepted.vue' /* webpackChunkName: "pages/message/accepted" */))
const _011d21e8 = () => interopDefault(import('../pages/message/denied.vue' /* webpackChunkName: "pages/message/denied" */))
const _5a19050f = () => interopDefault(import('../pages/message/hired.vue' /* webpackChunkName: "pages/message/hired" */))
const _9661eac0 = () => interopDefault(import('../pages/message/interview.vue' /* webpackChunkName: "pages/message/interview" */))
const _2eca54e9 = () => interopDefault(import('../pages/message/invites.vue' /* webpackChunkName: "pages/message/invites" */))
const _6c8a4514 = () => interopDefault(import('../pages/message/pending.vue' /* webpackChunkName: "pages/message/pending" */))
const _8716c448 = () => interopDefault(import('../pages/organization.vue' /* webpackChunkName: "pages/organization" */))
const _09dbc313 = () => interopDefault(import('../pages/organization/pricing.vue' /* webpackChunkName: "pages/organization/pricing" */))
const _90d7f914 = () => interopDefault(import('../pages/organization/profile.vue' /* webpackChunkName: "pages/organization/profile" */))
const _1543a14e = () => interopDefault(import('../pages/organization/user.vue' /* webpackChunkName: "pages/organization/user" */))
const _4a7afc86 = () => interopDefault(import('../pages/organization-create.vue' /* webpackChunkName: "pages/organization-create" */))
const _d5131a78 = () => interopDefault(import('../pages/talent-pool.vue' /* webpackChunkName: "pages/talent-pool" */))
const _493bc6c9 = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _aebe4472 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _012cf5ab = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7182c838 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _e55c815e = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _2be06687 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _43089370 = () => interopDefault(import('../pages/job-offer/create.vue' /* webpackChunkName: "pages/job-offer/create" */))
const _65c0ff0e = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _8a55780e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _8d1dea8a = () => interopDefault(import('../pages/job-offer/apply/_organization/_jobTitleId.vue' /* webpackChunkName: "pages/job-offer/apply/_organization/_jobTitleId" */))
const _4b94739a = () => interopDefault(import('../pages/applicant-profile/_id/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/index" */))
const _e0b6cd36 = () => interopDefault(import('../pages/application/_id/index.vue' /* webpackChunkName: "pages/application/_id/index" */))
const _04eb4ab9 = () => interopDefault(import('../pages/applicant-profile/_id/edit.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit" */))
const _43f9227c = () => interopDefault(import('../pages/applicant-profile/_id/edit/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/index" */))
const _10000db6 = () => interopDefault(import('../pages/applicant-profile/_id/edit/criteria.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/criteria" */))
const _55c0a55c = () => interopDefault(import('../pages/applicant-profile/_id/edit/education.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/education" */))
const _6a098230 = () => interopDefault(import('../pages/applicant-profile/_id/edit/experience.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/experience" */))
const _4d7d4b12 = () => interopDefault(import('../pages/applicant-profile/_id/edit/general.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/general" */))
const _6790ac45 = () => interopDefault(import('../pages/applicant-profile/_id/edit/languages.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/languages" */))
const _79c6c098 = () => interopDefault(import('../pages/applicant-profile/_id/edit/personality.vue' /* webpackChunkName: "pages/applicant-profile/_id/edit/personality" */))
const _2e6e3507 = () => interopDefault(import('../pages/applicant-profile/_id/quiz/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/index" */))
const _67380a5e = () => interopDefault(import('../pages/applicant-profile/_id/setup.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup" */))
const _c8425b3e = () => interopDefault(import('../pages/applicant-profile/_id/setup/index.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/index" */))
const _ac1ff9c0 = () => interopDefault(import('../pages/applicant-profile/_id/setup/criteria.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/criteria" */))
const _6130e2b7 = () => interopDefault(import('../pages/applicant-profile/_id/setup/education.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/education" */))
const _601ef96b = () => interopDefault(import('../pages/applicant-profile/_id/setup/experience.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/experience" */))
const _42b695b7 = () => interopDefault(import('../pages/applicant-profile/_id/setup/general.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/general" */))
const _18bc3cac = () => interopDefault(import('../pages/applicant-profile/_id/setup/languages.vue' /* webpackChunkName: "pages/applicant-profile/_id/setup/languages" */))
const _0349300b = () => interopDefault(import('../pages/job-offer/_id/edit.vue' /* webpackChunkName: "pages/job-offer/_id/edit" */))
const _2d56424e = () => interopDefault(import('../pages/job-offer/_id/edit/index.vue' /* webpackChunkName: "pages/job-offer/_id/edit/index" */))
const _ef36d7b8 = () => interopDefault(import('../pages/job-offer/_id/edit/education.vue' /* webpackChunkName: "pages/job-offer/_id/edit/education" */))
const _5411be64 = () => interopDefault(import('../pages/job-offer/_id/edit/general.vue' /* webpackChunkName: "pages/job-offer/_id/edit/general" */))
const _1ad59317 = () => interopDefault(import('../pages/job-offer/_id/edit/languages.vue' /* webpackChunkName: "pages/job-offer/_id/edit/languages" */))
const _6f6d3aea = () => interopDefault(import('../pages/job-offer/_id/edit/personality.vue' /* webpackChunkName: "pages/job-offer/_id/edit/personality" */))
const _34dae278 = () => interopDefault(import('../pages/job-offer/_id/edit/requirements.vue' /* webpackChunkName: "pages/job-offer/_id/edit/requirements" */))
const _17cb54d9 = () => interopDefault(import('../pages/job-offer/_id/quiz/index.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/index" */))
const _dafbd4be = () => interopDefault(import('../pages/applicant-profile/_id/quiz/intro.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/intro" */))
const _1b9de2eb = () => interopDefault(import('../pages/applicant-profile/_id/quiz/results.vue' /* webpackChunkName: "pages/applicant-profile/_id/quiz/results" */))
const _648462cf = () => interopDefault(import('../pages/job-offer/_id/quiz/manual.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/manual" */))
const _2232563d = () => interopDefault(import('../pages/job-offer/_id/quiz/results.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/results" */))
const _c642bc4a = () => interopDefault(import('../pages/job-offer/_id/quiz/test.vue' /* webpackChunkName: "pages/job-offer/_id/quiz/test" */))
const _fa002cc8 = () => interopDefault(import('../pages/job-offer/_id/_slug/index.vue' /* webpackChunkName: "pages/job-offer/_id/_slug/index" */))
const _8dc2ba9e = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/applicant-profile",
    component: _6ac58f48,
    name: "applicant-profile"
  }, {
    path: "/browse",
    component: _6c1e1473,
    name: "browse"
  }, {
    path: "/browser-outdated",
    component: _44cf9fd4,
    name: "browser-outdated"
  }, {
    path: "/dashboard",
    component: _539e158a,
    name: "dashboard"
  }, {
    path: "/job-offer",
    component: _5037fb36,
    name: "job-offer"
  }, {
    path: "/message",
    component: _156a50ee,
    children: [{
      path: "",
      component: _1245ecf1,
      name: "message"
    }, {
      path: "accepted",
      component: _0cf10a50,
      name: "message-accepted"
    }, {
      path: "denied",
      component: _011d21e8,
      name: "message-denied"
    }, {
      path: "hired",
      component: _5a19050f,
      name: "message-hired"
    }, {
      path: "interview",
      component: _9661eac0,
      name: "message-interview"
    }, {
      path: "invites",
      component: _2eca54e9,
      name: "message-invites"
    }, {
      path: "pending",
      component: _6c8a4514,
      name: "message-pending"
    }]
  }, {
    path: "/organization",
    component: _8716c448,
    name: "organization",
    children: [{
      path: "pricing",
      component: _09dbc313,
      name: "organization-pricing"
    }, {
      path: "profile",
      component: _90d7f914,
      name: "organization-profile"
    }, {
      path: "user",
      component: _1543a14e,
      name: "organization-user"
    }]
  }, {
    path: "/organization-create",
    component: _4a7afc86,
    name: "organization-create"
  }, {
    path: "/talent-pool",
    component: _d5131a78,
    name: "talent-pool"
  }, {
    path: "/welcome",
    component: _493bc6c9,
    name: "welcome"
  }, {
    path: "/auth/forgot-password",
    component: _aebe4472,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _012cf5ab,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _7182c838,
    name: "auth-logout"
  }, {
    path: "/auth/register",
    component: _e55c815e,
    name: "auth-register"
  }, {
    path: "/auth/reset-password",
    component: _2be06687,
    name: "auth-reset-password"
  }, {
    path: "/job-offer/create",
    component: _43089370,
    name: "job-offer-create"
  }, {
    path: "/user/profile",
    component: _65c0ff0e,
    name: "user-profile"
  }, {
    path: "/",
    component: _8a55780e,
    name: "index"
  }, {
    path: "/job-offer/apply/:organization/:jobTitleId?",
    component: _8d1dea8a,
    name: "job-offer-apply-organization-jobTitleId"
  }, {
    path: "/applicant-profile/:id",
    component: _4b94739a,
    name: "applicant-profile-id"
  }, {
    path: "/application/:id",
    component: _e0b6cd36,
    name: "application-id"
  }, {
    path: "/applicant-profile/:id?/edit",
    component: _04eb4ab9,
    children: [{
      path: "",
      component: _43f9227c,
      name: "applicant-profile-id-edit"
    }, {
      path: "criteria",
      component: _10000db6,
      name: "applicant-profile-id-edit-criteria"
    }, {
      path: "education",
      component: _55c0a55c,
      name: "applicant-profile-id-edit-education"
    }, {
      path: "experience",
      component: _6a098230,
      name: "applicant-profile-id-edit-experience"
    }, {
      path: "general",
      component: _4d7d4b12,
      name: "applicant-profile-id-edit-general"
    }, {
      path: "languages",
      component: _6790ac45,
      name: "applicant-profile-id-edit-languages"
    }, {
      path: "personality",
      component: _79c6c098,
      name: "applicant-profile-id-edit-personality"
    }]
  }, {
    path: "/applicant-profile/:id?/quiz",
    component: _2e6e3507,
    name: "applicant-profile-id-quiz"
  }, {
    path: "/applicant-profile/:id?/setup",
    component: _67380a5e,
    children: [{
      path: "",
      component: _c8425b3e,
      name: "applicant-profile-id-setup"
    }, {
      path: "criteria",
      component: _ac1ff9c0,
      name: "applicant-profile-id-setup-criteria"
    }, {
      path: "education",
      component: _6130e2b7,
      name: "applicant-profile-id-setup-education"
    }, {
      path: "experience",
      component: _601ef96b,
      name: "applicant-profile-id-setup-experience"
    }, {
      path: "general",
      component: _42b695b7,
      name: "applicant-profile-id-setup-general"
    }, {
      path: "languages",
      component: _18bc3cac,
      name: "applicant-profile-id-setup-languages"
    }]
  }, {
    path: "/job-offer/:id?/edit",
    component: _0349300b,
    children: [{
      path: "",
      component: _2d56424e,
      name: "job-offer-id-edit"
    }, {
      path: "education",
      component: _ef36d7b8,
      name: "job-offer-id-edit-education"
    }, {
      path: "general",
      component: _5411be64,
      name: "job-offer-id-edit-general"
    }, {
      path: "languages",
      component: _1ad59317,
      name: "job-offer-id-edit-languages"
    }, {
      path: "personality",
      component: _6f6d3aea,
      name: "job-offer-id-edit-personality"
    }, {
      path: "requirements",
      component: _34dae278,
      name: "job-offer-id-edit-requirements"
    }]
  }, {
    path: "/job-offer/:id?/quiz",
    component: _17cb54d9,
    name: "job-offer-id-quiz"
  }, {
    path: "/applicant-profile/:id?/quiz/intro",
    component: _dafbd4be,
    name: "applicant-profile-id-quiz-intro"
  }, {
    path: "/applicant-profile/:id?/quiz/results",
    component: _1b9de2eb,
    name: "applicant-profile-id-quiz-results"
  }, {
    path: "/job-offer/:id?/quiz/manual",
    component: _648462cf,
    name: "job-offer-id-quiz-manual"
  }, {
    path: "/job-offer/:id?/quiz/results",
    component: _2232563d,
    name: "job-offer-id-quiz-results"
  }, {
    path: "/job-offer/:id?/quiz/test",
    component: _c642bc4a,
    name: "job-offer-id-quiz-test"
  }, {
    path: "/job-offer/:id?/:slug",
    component: _fa002cc8,
    name: "job-offer-id-slug"
  }, {
    path: "/:slug",
    component: _8dc2ba9e,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
